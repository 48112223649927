import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'antd';
import {useApi} from "../ApiProvider";
import {UserDto} from "../../generated/api";
import {
    DollarCircleOutlined,
    FundProjectionScreenOutlined,
    LinkOutlined,
    LoginOutlined,
    MailOutlined,
    UserOutlined
} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import {MemoryViewer} from "./MemoryViewer";

interface SupportModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (description: string) => void;
}

export function AccountDetails({isOpen, onClose, onSubmit}: SupportModalProps) {
    const api = useApi();
    const [description, setDescription] = useState("");
    const [user, setUser] = useState<UserDto | null>(null);
    const [isMemoryModalVisible, setIsMemoryModalVisible] = useState(false);

    useEffect(() => {
        api.fetchUserInfo().then((data) => setUser(data.data));
    }, [api]);

    const handleOk = () => {
        onSubmit(description);
        setDescription('');
        onClose();
    };

    const handleCancel = () => {
        onClose();
        setDescription('');
    };

    return (
        <Modal
            title={<Title level={3}>Account Details</Title>}
            open={isOpen}
            onCancel={handleCancel}
            footer={[<div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div
                    style={{
                        paddingTop: 10,
                        paddingBottom: 0,
                        marginBottom: 0,
                        color: '#999',
                        fontSize: '12px',
                        opacity: 0.6,
                        pointerEvents: 'none'
                    }}
                >
                    App version: {user?.appVersion}
                </div>
                <Button key="submit" type="primary" onClick={handleOk}>
                    OK
                </Button>
            </div>
            ]}
            style={{top: 20, padding: '24px'}}
        >
            {/* Use a relative container so we can absolutely position the version label */}
            <div style={{position: 'relative'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                    {/* Left Side: User Details */}
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div>
                            <UserOutlined style={{marginRight: '8px'}}/>
                            <Text strong>Username:</Text> {user?.username}
                        </div>
                        <div>
                            <MailOutlined style={{marginRight: '8px'}}/>
                            <Text strong>Email:</Text> {user?.email}
                        </div>
                        <div>
                            <LinkOutlined style={{marginRight: '8px'}}/>
                            <a href="https://www.openworkflows.org/contact" target="_blank" rel="noopener noreferrer">
                                Get Help
                            </a>
                        </div>
                        <div>
                            <LinkOutlined style={{marginRight: '8px'}}/>
                            <a
                                href="https://ddx.openworkflows.org/realms/ddx/protocol/openid-connect/auth?client_id=ddx-app&redirect_uri=https://ddx.openworkflows.org/&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Reset Password
                            </a>
                        </div>
                        <Button style={{width: 170}} danger href="/logout">
                            <LoginOutlined style={{marginRight: '4px'}}/>
                            Log Out
                        </Button>
                    </div>

                    {/* Right Side: Account Details */}
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <div>
                            <DollarCircleOutlined style={{marginRight: '8px'}}/>
                            <Text strong>Account Balance:</Text> {user?.accountBalance?.toFixed(2)}$
                        </div>
                        <div>
                            <FundProjectionScreenOutlined style={{marginRight: '8px'}}/>
                            <Text strong>Total Runs:</Text> {user?.totalRuns}
                        </div>
                        <Button type="primary" onClick={() => setIsMemoryModalVisible(true)}>
                            Open Memory Manager
                        </Button>
                        <MemoryViewer
                            user={{}}
                            visible={isMemoryModalVisible}
                            onClose={() => setIsMemoryModalVisible(false)}
                        />
                    </div>
                </div>


            </div>
        </Modal>
    );
}
