/* tslint:disable */
/* eslint-disable */
/**
 * ddx-alunahealth
 * # Authentication Guide  We use the Bearer Token authentication mechanism for secure access to our APIs. This guide details the process of obtaining the token and using it to access our endpoints.  ## Obtaining the Bearer Token  1. **Endpoint**:      Retrieve the token from the following endpoint:     ```http    POST https://ddx.openworkflows.com/realms/ddx/protocol/openid-connect/token    ```  2. **Request Headers**:       ```http    Content-Type: application/x-www-form-urlencoded    ```  3. **Request Body** (x-www-form-urlencoded):     ```plaintext    client_id=ddx-app    username=YOUR_USERNAME    password=YOUR_PASSWORD    grant_type=password    ```     Replace `YOUR_USERNAME` and `YOUR_PASSWORD` with your actual account credentials.  4. **Response**:      A successful JSON response includes the `access_token`. Store it securely and use it to authenticate the API requests.      ```json    {      \"access_token\": \"YOUR_ACCESS_TOKEN\",      \"expires_in\": 300,      \"refresh_expires_in\": 1800,      \"token_type\": \"Bearer\"    }    ```     ## Using the Bearer Token for API Access  With the Bearer Token in hand:   **Set Authorization Header**:      Include the token in the `Authorization` header for your HTTP requests:     ```http    Authorization: Bearer YOUR_ACCESS_TOKEN    ```     Replace `YOUR_ACCESS_TOKEN` with the token you obtained from the previous step. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AgentConfig
 */
export interface AgentConfig {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfig
     */
    'inputVariables'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'prompt': string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'outputVariable': string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'tabNameToIterate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'tabName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfig
     */
    'saveResult'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentConfig
     */
    'streaming'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'aiModel'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentConfig
     */
    'temperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentConfig
     */
    'intermediatePrompt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentConfig
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AllWorkflowsWithDescription200ResponseValue
 */
export interface AllWorkflowsWithDescription200ResponseValue {
    /**
     * 
     * @type {string}
     * @memberof AllWorkflowsWithDescription200ResponseValue
     */
    'exampleCase': string;
    /**
     * 
     * @type {string}
     * @memberof AllWorkflowsWithDescription200ResponseValue
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ChatHistoryMessage
 */
export interface ChatHistoryMessage {
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryMessage
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryMessage
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof ChatHistoryMessage
     */
    'type': string;
    /**
     * 
     * @type {Date}
     * @memberof ChatHistoryMessage
     */
    'date': Date;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'tabName': string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'userComment': string;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    'addedDate'?: Date;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    'text': string;
    /**
     * 
     * @type {CommentType}
     * @memberof Comment
     */
    'type': CommentType;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'startPosition'?: number;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    'endPosition'?: number;
    /**
     * 
     * @type {Position}
     * @memberof Comment
     */
    'position'?: Position;
    /**
     * 
     * @type {Message}
     * @memberof Comment
     */
    'message'?: Message;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CommentType = {
    Comment: 'COMMENT',
    ThumbsUp: 'THUMBS_UP',
    ThumbsDown: 'THUMBS_DOWN'
} as const;

export type CommentType = typeof CommentType[keyof typeof CommentType];


/**
 * 
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'tabName': string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    'ruinId': string;
}
/**
 * 
 * @export
 * @interface ContinueAgentsChainRequest
 */
export interface ContinueAgentsChainRequest {
    /**
     * 
     * @type {string}
     * @memberof ContinueAgentsChainRequest
     */
    'tabName': string;
    /**
     * 
     * @type {string}
     * @memberof ContinueAgentsChainRequest
     */
    'executionId': string;
}
/**
 * 
 * @export
 * @interface ExternalMessage
 */
export interface ExternalMessage {
    /**
     * 
     * @type {string}
     * @memberof ExternalMessage
     */
    'input': string;
    /**
     * 
     * @type {Date}
     * @memberof ExternalMessage
     */
    'createdDate': Date;
    /**
     * 
     * @type {Array<Content>}
     * @memberof ExternalMessage
     */
    'content'?: Array<Content>;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalMessage
     */
    'finished': boolean;
}
/**
 * 
 * @export
 * @interface GiveAccessRoleToUserRequest
 */
export interface GiveAccessRoleToUserRequest {
    /**
     * 
     * @type {string}
     * @memberof GiveAccessRoleToUserRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof GiveAccessRoleToUserRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GiveAccessRoleToUserRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ImportedFile
 */
export interface ImportedFile {
    /**
     * 
     * @type {number}
     * @memberof ImportedFile
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'extractedText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'summarizationText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'rawData'?: string;
    /**
     * 
     * @type {Date}
     * @memberof ImportedFile
     */
    'createdDate'?: Date;
    /**
     * 
     * @type {Message}
     * @memberof ImportedFile
     */
    'message'?: Message;
    /**
     * 
     * @type {InternalChat}
     * @memberof ImportedFile
     */
    'internalChat'?: InternalChat;
}
/**
 * 
 * @export
 * @interface ImportedFileDto
 */
export interface ImportedFileDto {
    /**
     * 
     * @type {number}
     * @memberof ImportedFileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportedFileDto
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFileDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFileDto
     */
    'extractedText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportedFileDto
     */
    'summarizationText'?: string;
    /**
     * 
     * @type {Date}
     * @memberof ImportedFileDto
     */
    'createdDate'?: Date;
    /**
     * 
     * @type {Message}
     * @memberof ImportedFileDto
     */
    'message'?: Message;
    /**
     * 
     * @type {string}
     * @memberof ImportedFileDto
     */
    'rawData'?: string;
}
/**
 * 
 * @export
 * @interface InternalChat
 */
export interface InternalChat {
    /**
     * 
     * @type {number}
     * @memberof InternalChat
     */
    'id': number;
    /**
     * 
     * @type {Message}
     * @memberof InternalChat
     */
    'message': Message;
    /**
     * 
     * @type {number}
     * @memberof InternalChat
     */
    'messageId': number;
    /**
     * 
     * @type {string}
     * @memberof InternalChat
     */
    'threadId': string;
    /**
     * 
     * @type {string}
     * @memberof InternalChat
     */
    'assistantId': string;
    /**
     * 
     * @type {string}
     * @memberof InternalChat
     */
    'agentName': string;
    /**
     * 
     * @type {boolean}
     * @memberof InternalChat
     */
    'finished': boolean;
    /**
     * 
     * @type {Array<ChatHistoryMessage>}
     * @memberof InternalChat
     */
    'chatHistoryMessages'?: Array<ChatHistoryMessage>;
    /**
     * 
     * @type {Array<ImportedFile>}
     * @memberof InternalChat
     */
    'importedFile'?: Array<ImportedFile>;
}
/**
 * 
 * @export
 * @interface LinePosition
 */
export interface LinePosition {
    /**
     * 
     * @type {number}
     * @memberof LinePosition
     */
    'line': number;
    /**
     * 
     * @type {number}
     * @memberof LinePosition
     */
    'column': number;
}
/**
 * 
 * @export
 * @interface Memory
 */
export interface Memory {
    /**
     * 
     * @type {number}
     * @memberof Memory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof Memory
     */
    'value': string;
    /**
     * 
     * @type {User}
     * @memberof Memory
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof Memory
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof Message
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'input': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'executionId': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'groupId': string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    'createdDate': Date;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    'updatedDate'?: Date;
    /**
     * 
     * @type {Array<Content>}
     * @memberof Message
     */
    'content': Array<Content>;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'finished'?: boolean;
    /**
     * 
     * @type {Array<ImportedFile>}
     * @memberof Message
     */
    'importedFile'?: Array<ImportedFile>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Message
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'summarizationText': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'workflow': string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'externalCall': boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'shortName': string;
    /**
     * 
     * @type {MessageState}
     * @memberof Message
     */
    'state': MessageState;
    /**
     * 
     * @type {Array<Question>}
     * @memberof Message
     */
    'additionalQuestions': Array<Question>;
    /**
     * 
     * @type {Array<InternalChat>}
     * @memberof Message
     */
    'internalChats': Array<InternalChat>;
}


/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'input': string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'executionId': string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'workflow'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'summarizationText'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'groupId': string;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'description'?: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    'createdDate': Date;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    'updatedDate'?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDto
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {Array<ImportedFileDto>}
     * @memberof MessageDto
     */
    'importedFile'?: Array<ImportedFileDto>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof MessageDto
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDto
     */
    'externalCall'?: boolean;
    /**
     * 
     * @type {Array<Question>}
     * @memberof MessageDto
     */
    'additionalQuestions'?: Array<Question>;
    /**
     * 
     * @type {MessageState}
     * @memberof MessageDto
     */
    'state'?: MessageState;
    /**
     * 
     * @type {Array<Content>}
     * @memberof MessageDto
     */
    'content'?: Array<Content>;
}


/**
 * 
 * @export
 * @interface MessageHeaderDto
 */
export interface MessageHeaderDto {
    /**
     * 
     * @type {number}
     * @memberof MessageHeaderDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'input': string;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'executionId': string;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'workflow'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'summarizationText'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'groupId': string;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'description'?: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageHeaderDto
     */
    'createdDate': Date;
    /**
     * 
     * @type {Date}
     * @memberof MessageHeaderDto
     */
    'updatedDate'?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MessageHeaderDto
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {Array<ImportedFileDto>}
     * @memberof MessageHeaderDto
     */
    'importedFile'?: Array<ImportedFileDto>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof MessageHeaderDto
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {string}
     * @memberof MessageHeaderDto
     */
    'shortName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageHeaderDto
     */
    'externalCall'?: boolean;
    /**
     * 
     * @type {Array<Question>}
     * @memberof MessageHeaderDto
     */
    'additionalQuestions'?: Array<Question>;
    /**
     * 
     * @type {MessageState}
     * @memberof MessageHeaderDto
     */
    'state'?: MessageState;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MessageState = {
    Running: 'Running',
    WaitingForAnswers: 'WaitingForAnswers',
    AnswersIsReady: 'AnswersIsReady',
    Finished: 'Finished',
    Error: 'Error'
} as const;

export type MessageState = typeof MessageState[keyof typeof MessageState];


/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {LinePosition}
     * @memberof Position
     */
    'start': LinePosition;
    /**
     * 
     * @type {LinePosition}
     * @memberof Position
     */
    'end': LinePosition;
}
/**
 * 
 * @export
 * @interface ProcessMessage200Response
 */
export interface ProcessMessage200Response {
    /**
     * 
     * @type {MessageHeaderDto}
     * @memberof ProcessMessage200Response
     */
    'message': MessageHeaderDto;
    /**
     * 
     * @type {string}
     * @memberof ProcessMessage200Response
     */
    'executionId': string;
}
/**
 * 
 * @export
 * @interface Prompt
 */
export interface Prompt {
    /**
     * 
     * @type {string}
     * @memberof Prompt
     */
    'tabName': string;
    /**
     * 
     * @type {string}
     * @memberof Prompt
     */
    'prompt': string;
}
/**
 * 
 * @export
 * @interface PromptToConfigRequest
 */
export interface PromptToConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof PromptToConfigRequest
     */
    'prompt': string;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'text': string;
    /**
     * 
     * @type {Message}
     * @memberof Question
     */
    'message'?: Message;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'tabName'?: string;
    /**
     * 
     * @type {QuestionState}
     * @memberof Question
     */
    'questionState'?: QuestionState;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'answer'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const QuestionState = {
    Answered: 'Answered',
    Skipped: 'Skipped',
    WaitingForAnswer: 'WaitingForAnswer'
} as const;

export type QuestionState = typeof QuestionState[keyof typeof QuestionState];


/**
 * 
 * @export
 * @interface RefillAccountRequest
 */
export interface RefillAccountRequest {
    /**
     * 
     * @type {number}
     * @memberof RefillAccountRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof RefillAccountRequest
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface RequestMessage
 */
export interface RequestMessage {
    /**
     * 
     * @type {string}
     * @memberof RequestMessage
     */
    'executionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestMessage
     */
    'input': string;
    /**
     * 
     * @type {Array<Prompt>}
     * @memberof RequestMessage
     */
    'prompts'?: Array<Prompt>;
    /**
     * 
     * @type {string}
     * @memberof RequestMessage
     */
    'workflow'?: string;
}
/**
 * 
 * @export
 * @interface SetMemoryForUserRequest
 */
export interface SetMemoryForUserRequest {
    /**
     * 
     * @type {string}
     * @memberof SetMemoryForUserRequest
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof SetMemoryForUserRequest
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof SetMemoryForUserRequest
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface SetPermissionToWorkflowRequest
 */
export interface SetPermissionToWorkflowRequest {
    /**
     * 
     * @type {string}
     * @memberof SetPermissionToWorkflowRequest
     */
    'workflowName': string;
    /**
     * 
     * @type {string}
     * @memberof SetPermissionToWorkflowRequest
     */
    'userEmail': string;
}
/**
 * 
 * @export
 * @interface ShareMessage200Response
 */
export interface ShareMessage200Response {
    /**
     * 
     * @type {string}
     * @memberof ShareMessage200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ShareMessageRequest
 */
export interface ShareMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof ShareMessageRequest
     */
    'username': string;
    /**
     * 
     * @type {number}
     * @memberof ShareMessageRequest
     */
    'messageId'?: number;
}
/**
 * 
 * @export
 * @interface ShareWithSupportRequest
 */
export interface ShareWithSupportRequest {
    /**
     * 
     * @type {string}
     * @memberof ShareWithSupportRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ShareWithSupportRequest
     */
    'messageId'?: number;
}
/**
 * 
 * @export
 * @interface ShortName200Response
 */
export interface ShortName200Response {
    /**
     * 
     * @type {number}
     * @memberof ShortName200Response
     */
    'id': number;
    /**
     * 
     * @type {User}
     * @memberof ShortName200Response
     */
    'user': User;
    /**
     * 
     * @type {number}
     * @memberof ShortName200Response
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'input': string;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'executionId': string;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'groupId': string;
    /**
     * 
     * @type {Date}
     * @memberof ShortName200Response
     */
    'createdDate': Date;
    /**
     * 
     * @type {Date}
     * @memberof ShortName200Response
     */
    'updatedDate'?: Date;
    /**
     * 
     * @type {Array<Content>}
     * @memberof ShortName200Response
     */
    'content': Array<Content>;
    /**
     * 
     * @type {boolean}
     * @memberof ShortName200Response
     */
    'isShared'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShortName200Response
     */
    'finished'?: boolean;
    /**
     * 
     * @type {Array<ImportedFile>}
     * @memberof ShortName200Response
     */
    'importedFile'?: Array<ImportedFile>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof ShortName200Response
     */
    'comments'?: Array<Comment>;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'summarizationText': string;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'workflow': string;
    /**
     * 
     * @type {boolean}
     * @memberof ShortName200Response
     */
    'externalCall': boolean;
    /**
     * 
     * @type {string}
     * @memberof ShortName200Response
     */
    'shortName': string;
    /**
     * 
     * @type {MessageState}
     * @memberof ShortName200Response
     */
    'state': MessageState;
    /**
     * 
     * @type {Array<Question>}
     * @memberof ShortName200Response
     */
    'additionalQuestions': Array<Question>;
    /**
     * 
     * @type {Array<InternalChat>}
     * @memberof ShortName200Response
     */
    'internalChats': Array<InternalChat>;
}


/**
 * 
 * @export
 * @interface ShortNameRequest
 */
export interface ShortNameRequest {
    /**
     * 
     * @type {string}
     * @memberof ShortNameRequest
     */
    'executionId': string;
}
/**
 * 
 * @export
 * @interface SubmitProcessFullSummary200Response
 */
export interface SubmitProcessFullSummary200Response {
    /**
     * 
     * @type {ExternalMessage}
     * @memberof SubmitProcessFullSummary200Response
     */
    'message': ExternalMessage;
    /**
     * 
     * @type {string}
     * @memberof SubmitProcessFullSummary200Response
     */
    'executionId': string;
}
/**
 * 
 * @export
 * @interface SummarizationConfig
 */
export interface SummarizationConfig {
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'inputType'?: SummarizationConfigInputTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'aiModel'?: string;
    /**
     * 
     * @type {number}
     * @memberof SummarizationConfig
     */
    'temperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'promptTemplate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'refineTemplate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'prompt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'refine'?: string;
    /**
     * 
     * @type {string}
     * @memberof SummarizationConfig
     */
    'type'?: SummarizationConfigTypeEnum;
}

export const SummarizationConfigInputTypeEnum = {
    Text: 'text',
    Picture: 'picture'
} as const;

export type SummarizationConfigInputTypeEnum = typeof SummarizationConfigInputTypeEnum[keyof typeof SummarizationConfigInputTypeEnum];
export const SummarizationConfigTypeEnum = {
    Refine: 'refine',
    MapReduce: 'map_reduce',
    Stuff: 'stuff'
} as const;

export type SummarizationConfigTypeEnum = typeof SummarizationConfigTypeEnum[keyof typeof SummarizationConfigTypeEnum];

/**
 * 
 * @export
 * @interface Transcript200Response
 */
export interface Transcript200Response {
    /**
     * 
     * @type {string}
     * @memberof Transcript200Response
     */
    'result': string;
}
/**
 * 
 * @export
 * @interface UiConfig
 */
export interface UiConfig {
    /**
     * 
     * @type {string}
     * @memberof UiConfig
     */
    'inputDescription'?: string;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowPublicStatusRequest
 */
export interface UpdateWorkflowPublicStatusRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowPublicStatusRequest
     */
    'public': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowPublicStatusRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateWorkflowStatusRequest
 */
export interface UpdateWorkflowStatusRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkflowStatusRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateWorkflowStatusRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    'registrationDate'?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'tokenLimit': number;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'notify': boolean;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserDto
     */
    'registrationDate'?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDto
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastLogin'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'totalTokens'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'totalRuns'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'organization'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'accountBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'appVersion'?: string;
}
/**
 * 
 * @export
 * @interface ValidationConfig
 */
export interface ValidationConfig {
    /**
     * 
     * @type {string}
     * @memberof ValidationConfig
     */
    'prompt': string;
}
/**
 * 
 * @export
 * @interface WorkflowConfigDto
 */
export interface WorkflowConfigDto {
    /**
     * 
     * @type {Array<AgentConfig>}
     * @memberof WorkflowConfigDto
     */
    'agents': Array<AgentConfig>;
    /**
     * 
     * @type {string}
     * @memberof WorkflowConfigDto
     */
    'info'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowConfigDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowConfigDto
     */
    'aiModel'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowConfigDto
     */
    'temperature'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkflowConfigDto
     */
    'shortNamePrompt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowConfigDto
     */
    'autoRun'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowConfigDto
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkflowConfigDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowConfigDto
     */
    'name': string;
    /**
     * 
     * @type {ValidationConfig}
     * @memberof WorkflowConfigDto
     */
    'validation'?: ValidationConfig;
    /**
     * 
     * @type {SummarizationConfig}
     * @memberof WorkflowConfigDto
     */
    'summarization'?: SummarizationConfig;
    /**
     * 
     * @type {string}
     * @memberof WorkflowConfigDto
     */
    'exampleCase'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowConfigDto
     */
    'public'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkflowConfigDto
     */
    'pricePerRun'?: number;
    /**
     * 
     * @type {UiConfig}
     * @memberof WorkflowConfigDto
     */
    'ui'?: UiConfig;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWorkflowsConfigs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWorkflowsWithDescription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workflow/description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} tabName 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFileToChat: async (executionId: string, tabName: string, attachedFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionId' is not null or undefined
            assertParamExists('attachFileToChat', 'executionId', executionId)
            // verify required parameter 'tabName' is not null or undefined
            assertParamExists('attachFileToChat', 'tabName', tabName)
            const localVarPath = `/api/chat/attachToChat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (executionId !== undefined) { 
                localVarFormParams.append('executionId', executionId as any);
            }
    
            if (tabName !== undefined) { 
                localVarFormParams.append('tabName', tabName as any);
            }
                if (attachedFiles) {
                attachedFiles.forEach((element) => {
                    localVarFormParams.append('attachedFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackApp: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fhir/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContinueAgentsChainRequest} continueAgentsChainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueAgentsChain: async (continueAgentsChainRequest: ContinueAgentsChainRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'continueAgentsChainRequest' is not null or undefined
            assertParamExists('continueAgentsChain', 'continueAgentsChainRequest', continueAgentsChainRequest)
            const localVarPath = `/api/chat/continue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(continueAgentsChainRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentForMessage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCommentForMessage', 'id', id)
            const localVarPath = `/api/comment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemory: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMemory', 'id', id)
            const localVarPath = `/api/memory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMessage', 'id', id)
            const localVarPath = `/api/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/current/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/allusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWaitingUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/waitingusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} executionid 
         * @param {string} tab 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatHistory: async (executionid: string, tab: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionid' is not null or undefined
            assertParamExists('getChatHistory', 'executionid', executionid)
            // verify required parameter 'tab' is not null or undefined
            assertParamExists('getChatHistory', 'tab', tab)
            const localVarPath = `/api/chat/history/{executionid}/{tab}`
                .replace(`{${"executionid"}}`, encodeURIComponent(String(executionid)))
                .replace(`{${"tab"}}`, encodeURIComponent(String(tab)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocxForMessageId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocxForMessageId', 'id', id)
            const localVarPath = `/api/messages/docx/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryForUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/memory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessageById', 'id', id)
            const localVarPath = `/api/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageByIdForUser: async (messageId: string, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getMessageByIdForUser', 'messageId', messageId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getMessageByIdForUser', 'userId', userId)
            const localVarPath = `/api/admin/message/{messageId}/user/{userId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHeaders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHeadersForUser: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessageHeadersForUser', 'id', id)
            const localVarPath = `/api/admin/userheaders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesIdsForGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMessagesIdsForGroup', 'id', id)
            const localVarPath = `/api/messages/group/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfForMessageId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPdfForMessageId', 'id', id)
            const localVarPath = `/api/messages/pdf/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/user/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwaggerConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/swagger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnfinishedRuns: async (workflow: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflow' is not null or undefined
            assertParamExists('getUnfinishedRuns', 'workflow', workflow)
            const localVarPath = `/api/messages/unfinished/{workflow}`
                .replace(`{${"workflow"}}`, encodeURIComponent(String(workflow)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GiveAccessRoleToUserRequest} giveAccessRoleToUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveAccessRoleToUser: async (giveAccessRoleToUserRequest: GiveAccessRoleToUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giveAccessRoleToUserRequest' is not null or undefined
            assertParamExists('giveAccessRoleToUser', 'giveAccessRoleToUserRequest', giveAccessRoleToUserRequest)
            const localVarPath = `/api/admin/user/access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giveAccessRoleToUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [iss] 
         * @param {string} [launch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchApp: async (iss?: string, launch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fhir/cerner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (iss !== undefined) {
                localVarQueryParameter['iss'] = iss;
            }

            if (launch !== undefined) {
                localVarQueryParameter['launch'] = launch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [input] 
         * @param {string} [executionId] 
         * @param {string} [workflow] 
         * @param {string} [groupId] 
         * @param {string} [id] 
         * @param {string} [comments] 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMessage: async (input?: string, executionId?: string, workflow?: string, groupId?: string, id?: string, comments?: string, attachedFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (input !== undefined) { 
                localVarFormParams.append('input', input as any);
            }
    
            if (executionId !== undefined) { 
                localVarFormParams.append('executionId', executionId as any);
            }
    
            if (workflow !== undefined) { 
                localVarFormParams.append('workflow', workflow as any);
            }
    
            if (groupId !== undefined) { 
                localVarFormParams.append('groupId', groupId as any);
            }
    
            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (comments !== undefined) { 
                localVarFormParams.append('comments', comments as any);
            }
                if (attachedFiles) {
                attachedFiles.forEach((element) => {
                    localVarFormParams.append('attachedFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process input message by After Visit Summary agent and return executionId to check status after
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMessageExtWithPrompt: async (requestMessage: RequestMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestMessage' is not null or undefined
            assertParamExists('processMessageExtWithPrompt', 'requestMessage', requestMessage)
            const localVarPath = `/external/api/v1/promptprocess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} [text] 
         * @param {string} [tabName] 
         * @param {string} [chatMessageId] 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processUserMessageFromChat: async (executionId: string, text?: string, tabName?: string, chatMessageId?: string, attachedFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionId' is not null or undefined
            assertParamExists('processUserMessageFromChat', 'executionId', executionId)
            const localVarPath = `/api/chat/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (executionId !== undefined) { 
                localVarFormParams.append('executionId', executionId as any);
            }
    
            if (text !== undefined) { 
                localVarFormParams.append('text', text as any);
            }
    
            if (tabName !== undefined) { 
                localVarFormParams.append('tabName', tabName as any);
            }
    
            if (chatMessageId !== undefined) { 
                localVarFormParams.append('chatMessageId', chatMessageId as any);
            }
                if (attachedFiles) {
                attachedFiles.forEach((element) => {
                    localVarFormParams.append('attachedFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromptToConfigRequest} promptToConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptToConfig: async (promptToConfigRequest: PromptToConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptToConfigRequest' is not null or undefined
            assertParamExists('promptToConfig', 'promptToConfigRequest', promptToConfigRequest)
            const localVarPath = `/api/workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptToConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawData: async (executionId: string, fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionId' is not null or undefined
            assertParamExists('rawData', 'executionId', executionId)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('rawData', 'fileName', fileName)
            const localVarPath = `/api/file/raw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (executionId !== undefined) {
                localVarQueryParameter['executionId'] = executionId;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawDataForFile: async (fileName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('rawDataForFile', 'fileName', fileName)
            const localVarPath = `/api/file/raw/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefillAccountRequest} refillAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillAccount: async (refillAccountRequest: RefillAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refillAccountRequest' is not null or undefined
            assertParamExists('refillAccount', 'refillAccountRequest', refillAccountRequest)
            const localVarPath = `/api/admin/user/refill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refillAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Comment} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCommentForMessage: async (id: string, comment: Comment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveCommentForMessage', 'id', id)
            // verify required parameter 'comment' is not null or undefined
            assertParamExists('saveCommentForMessage', 'comment', comment)
            const localVarPath = `/api/comment/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tabName 
         * @param {Array<Question>} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveQuestionsWithAnswersForMessage: async (id: string, tabName: string, question?: Array<Question>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveQuestionsWithAnswersForMessage', 'id', id)
            // verify required parameter 'tabName' is not null or undefined
            assertParamExists('saveQuestionsWithAnswersForMessage', 'tabName', tabName)
            const localVarPath = `/api/question/message/{id}/{tabName}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tabName"}}`, encodeURIComponent(String(tabName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(question, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkflowConfigDto} workflowConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveWorkflowConfig: async (workflowConfigDto: WorkflowConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workflowConfigDto' is not null or undefined
            assertParamExists('saveWorkflowConfig', 'workflowConfigDto', workflowConfigDto)
            const localVarPath = `/api/workflow/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workflowConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetMemoryForUserRequest} setMemoryForUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMemoryForUser: async (setMemoryForUserRequest: SetMemoryForUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setMemoryForUserRequest' is not null or undefined
            assertParamExists('setMemoryForUser', 'setMemoryForUserRequest', setMemoryForUserRequest)
            const localVarPath = `/api/memory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setMemoryForUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPermissionToWorkflowRequest} setPermissionToWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPermissionToWorkflow: async (setPermissionToWorkflowRequest: SetPermissionToWorkflowRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPermissionToWorkflowRequest' is not null or undefined
            assertParamExists('setPermissionToWorkflow', 'setPermissionToWorkflowRequest', setPermissionToWorkflowRequest)
            const localVarPath = `/api/workflow/permission/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPermissionToWorkflowRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShareMessageRequest} shareMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareMessage: async (shareMessageRequest: ShareMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareMessageRequest' is not null or undefined
            assertParamExists('shareMessage', 'shareMessageRequest', shareMessageRequest)
            const localVarPath = `/api/message/share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShareWithSupportRequest} shareWithSupportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareWithSupport: async (shareWithSupportRequest: ShareWithSupportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareWithSupportRequest' is not null or undefined
            assertParamExists('shareWithSupport', 'shareWithSupportRequest', shareWithSupportRequest)
            const localVarPath = `/api/message/support/share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareWithSupportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShortNameRequest} shortNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortName: async (shortNameRequest: ShortNameRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shortNameRequest' is not null or undefined
            assertParamExists('shortName', 'shortNameRequest', shortNameRequest)
            const localVarPath = `/api/message/shortname`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shortNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcript: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('transcript', 'file', file)
            const localVarPath = `/external/api/v1/audio/transcript`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} initialStart 
         * @param {string} id 
         * @param {File} file 
         * @param {string} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcriptAudio: async (initialStart: string, id: string, file: File, input: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'initialStart' is not null or undefined
            assertParamExists('transcriptAudio', 'initialStart', initialStart)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('transcriptAudio', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('transcriptAudio', 'file', file)
            // verify required parameter 'input' is not null or undefined
            assertParamExists('transcriptAudio', 'input', input)
            const localVarPath = `/api/transcription/openai/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (initialStart !== undefined) {
                localVarQueryParameter['initialStart'] = initialStart;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (input !== undefined) { 
                localVarFormParams.append('input', input as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWorkflowPublicStatusRequest} updateWorkflowPublicStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowPublicStatus: async (updateWorkflowPublicStatusRequest: UpdateWorkflowPublicStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWorkflowPublicStatusRequest' is not null or undefined
            assertParamExists('updateWorkflowPublicStatus', 'updateWorkflowPublicStatusRequest', updateWorkflowPublicStatusRequest)
            const localVarPath = `/api/workflow/update/status/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowPublicStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateWorkflowStatusRequest} updateWorkflowStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowStatus: async (updateWorkflowStatusRequest: UpdateWorkflowStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateWorkflowStatusRequest' is not null or undefined
            assertParamExists('updateWorkflowStatus', 'updateWorkflowStatusRequest', updateWorkflowStatusRequest)
            const localVarPath = `/api/workflow/update/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWorkflowStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('workflowByName', 'name', name)
            const localVarPath = `/api/workflow/find/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWorkflowsConfigs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: WorkflowConfigDto; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWorkflowsConfigs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allWorkflowsWithDescription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: AllWorkflowsWithDescription200ResponseValue; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allWorkflowsWithDescription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} tabName 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachFileToChat(executionId: string, tabName: string, attachedFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachFileToChat(executionId, tabName, attachedFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callbackApp(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callbackApp(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContinueAgentsChainRequest} continueAgentsChainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continueAgentsChain(continueAgentsChainRequest: ContinueAgentsChainRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.continueAgentsChain(continueAgentsChainRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentForMessage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentForMessage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMemory(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMemory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWaitingUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWaitingUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} executionid 
         * @param {string} tab 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatHistory(executionid: string, tab: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalChat>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatHistory(executionid, tab, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocxForMessageId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocxForMessageId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMemoryForUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Memory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMemoryForUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} messageId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageByIdForUser(messageId: string, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageByIdForUser(messageId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageHeaders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageHeaderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageHeaders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageHeadersForUser(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageHeaderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageHeadersForUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessagesIdsForGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessagesIdsForGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfForMessageId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfForMessageId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSwaggerConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSwaggerConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnfinishedRuns(workflow: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnfinishedRuns(workflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GiveAccessRoleToUserRequest} giveAccessRoleToUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giveAccessRoleToUser(giveAccessRoleToUserRequest: GiveAccessRoleToUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giveAccessRoleToUser(giveAccessRoleToUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [iss] 
         * @param {string} [launch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async launchApp(iss?: string, launch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.launchApp(iss, launch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [input] 
         * @param {string} [executionId] 
         * @param {string} [workflow] 
         * @param {string} [groupId] 
         * @param {string} [id] 
         * @param {string} [comments] 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMessage(input?: string, executionId?: string, workflow?: string, groupId?: string, id?: string, comments?: string, attachedFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessMessage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processMessage(input, executionId, workflow, groupId, id, comments, attachedFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process input message by After Visit Summary agent and return executionId to check status after
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMessageExtWithPrompt(requestMessage: RequestMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitProcessFullSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processMessageExtWithPrompt(requestMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} [text] 
         * @param {string} [tabName] 
         * @param {string} [chatMessageId] 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processUserMessageFromChat(executionId: string, text?: string, tabName?: string, chatMessageId?: string, attachedFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processUserMessageFromChat(executionId, text, tabName, chatMessageId, attachedFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromptToConfigRequest} promptToConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promptToConfig(promptToConfigRequest: PromptToConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promptToConfig(promptToConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rawData(executionId: string, fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rawData(executionId, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rawDataForFile(fileName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rawDataForFile(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefillAccountRequest} refillAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillAccount(refillAccountRequest: RefillAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillAccount(refillAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Comment} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCommentForMessage(id: string, comment: Comment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCommentForMessage(id, comment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tabName 
         * @param {Array<Question>} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveQuestionsWithAnswersForMessage(id: string, tabName: string, question?: Array<Question>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionsWithAnswersForMessage(id, tabName, question, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkflowConfigDto} workflowConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveWorkflowConfig(workflowConfigDto: WorkflowConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveWorkflowConfig(workflowConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetMemoryForUserRequest} setMemoryForUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMemoryForUser(setMemoryForUserRequest: SetMemoryForUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Memory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMemoryForUser(setMemoryForUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetPermissionToWorkflowRequest} setPermissionToWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPermissionToWorkflow(setPermissionToWorkflowRequest: SetPermissionToWorkflowRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPermissionToWorkflow(setPermissionToWorkflowRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ShareMessageRequest} shareMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareMessage(shareMessageRequest: ShareMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareMessage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareMessage(shareMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ShareWithSupportRequest} shareWithSupportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareWithSupport(shareWithSupportRequest: ShareWithSupportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareMessage200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareWithSupport(shareWithSupportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ShortNameRequest} shortNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shortName(shortNameRequest: ShortNameRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortName200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shortName(shortNameRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transcript(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Transcript200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transcript(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} initialStart 
         * @param {string} id 
         * @param {File} file 
         * @param {string} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transcriptAudio(initialStart: string, id: string, file: File, input: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transcriptAudio(initialStart, id, file, input, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWorkflowPublicStatusRequest} updateWorkflowPublicStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowPublicStatus(updateWorkflowPublicStatusRequest: UpdateWorkflowPublicStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowPublicStatus(updateWorkflowPublicStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateWorkflowStatusRequest} updateWorkflowStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWorkflowStatus(updateWorkflowStatusRequest: UpdateWorkflowStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWorkflowStatus(updateWorkflowStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workflowByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkflowConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workflowByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWorkflowsConfigs(options?: any): AxiosPromise<{ [key: string]: WorkflowConfigDto; }> {
            return localVarFp.allWorkflowsConfigs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allWorkflowsWithDescription(options?: any): AxiosPromise<{ [key: string]: AllWorkflowsWithDescription200ResponseValue; }> {
            return localVarFp.allWorkflowsWithDescription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} tabName 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachFileToChat(executionId: string, tabName: string, attachedFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.attachFileToChat(executionId, tabName, attachedFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackApp(options?: any): AxiosPromise<any> {
            return localVarFp.callbackApp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContinueAgentsChainRequest} continueAgentsChainRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continueAgentsChain(continueAgentsChainRequest: ContinueAgentsChainRequest, options?: any): AxiosPromise<void> {
            return localVarFp.continueAgentsChain(continueAgentsChainRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentForMessage(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.deleteCommentForMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMemory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMemory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchUserInfo(options?: any): AxiosPromise<UserDto> {
            return localVarFp.fetchUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getAllUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWaitingUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.getAllWaitingUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} executionid 
         * @param {string} tab 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatHistory(executionid: string, tab: string, options?: any): AxiosPromise<InternalChat> {
            return localVarFp.getChatHistory(executionid, tab, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocxForMessageId(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDocxForMessageId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMemoryForUser(options?: any): AxiosPromise<Array<Memory>> {
            return localVarFp.getMemoryForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageById(id: string, options?: any): AxiosPromise<MessageDto> {
            return localVarFp.getMessageById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageByIdForUser(messageId: string, userId: number, options?: any): AxiosPromise<MessageDto> {
            return localVarFp.getMessageByIdForUser(messageId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHeaders(options?: any): AxiosPromise<Array<MessageHeaderDto>> {
            return localVarFp.getMessageHeaders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageHeadersForUser(id: number, options?: any): AxiosPromise<Array<MessageHeaderDto>> {
            return localVarFp.getMessageHeadersForUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessagesIdsForGroup(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getMessagesIdsForGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfForMessageId(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPdfForMessageId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSwaggerConfig(options?: any): AxiosPromise<any> {
            return localVarFp.getSwaggerConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnfinishedRuns(workflow: string, options?: any): AxiosPromise<any> {
            return localVarFp.getUnfinishedRuns(workflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GiveAccessRoleToUserRequest} giveAccessRoleToUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giveAccessRoleToUser(giveAccessRoleToUserRequest: GiveAccessRoleToUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.giveAccessRoleToUser(giveAccessRoleToUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [iss] 
         * @param {string} [launch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launchApp(iss?: string, launch?: string, options?: any): AxiosPromise<void> {
            return localVarFp.launchApp(iss, launch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [input] 
         * @param {string} [executionId] 
         * @param {string} [workflow] 
         * @param {string} [groupId] 
         * @param {string} [id] 
         * @param {string} [comments] 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMessage(input?: string, executionId?: string, workflow?: string, groupId?: string, id?: string, comments?: string, attachedFiles?: Array<File>, options?: any): AxiosPromise<ProcessMessage200Response> {
            return localVarFp.processMessage(input, executionId, workflow, groupId, id, comments, attachedFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * Process input message by After Visit Summary agent and return executionId to check status after
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMessageExtWithPrompt(requestMessage: RequestMessage, options?: any): AxiosPromise<SubmitProcessFullSummary200Response> {
            return localVarFp.processMessageExtWithPrompt(requestMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} [text] 
         * @param {string} [tabName] 
         * @param {string} [chatMessageId] 
         * @param {Array<File>} [attachedFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processUserMessageFromChat(executionId: string, text?: string, tabName?: string, chatMessageId?: string, attachedFiles?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.processUserMessageFromChat(executionId, text, tabName, chatMessageId, attachedFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromptToConfigRequest} promptToConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promptToConfig(promptToConfigRequest: PromptToConfigRequest, options?: any): AxiosPromise<string> {
            return localVarFp.promptToConfig(promptToConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} executionId 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawData(executionId: string, fileName: string, options?: any): AxiosPromise<string> {
            return localVarFp.rawData(executionId, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rawDataForFile(fileName: string, options?: any): AxiosPromise<void> {
            return localVarFp.rawDataForFile(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefillAccountRequest} refillAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillAccount(refillAccountRequest: RefillAccountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.refillAccount(refillAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Comment} comment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCommentForMessage(id: string, comment: Comment, options?: any): AxiosPromise<Comment> {
            return localVarFp.saveCommentForMessage(id, comment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} tabName 
         * @param {Array<Question>} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveQuestionsWithAnswersForMessage(id: string, tabName: string, question?: Array<Question>, options?: any): AxiosPromise<void> {
            return localVarFp.saveQuestionsWithAnswersForMessage(id, tabName, question, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkflowConfigDto} workflowConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveWorkflowConfig(workflowConfigDto: WorkflowConfigDto, options?: any): AxiosPromise<void> {
            return localVarFp.saveWorkflowConfig(workflowConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetMemoryForUserRequest} setMemoryForUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMemoryForUser(setMemoryForUserRequest: SetMemoryForUserRequest, options?: any): AxiosPromise<Memory> {
            return localVarFp.setMemoryForUser(setMemoryForUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPermissionToWorkflowRequest} setPermissionToWorkflowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPermissionToWorkflow(setPermissionToWorkflowRequest: SetPermissionToWorkflowRequest, options?: any): AxiosPromise<void> {
            return localVarFp.setPermissionToWorkflow(setPermissionToWorkflowRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShareMessageRequest} shareMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareMessage(shareMessageRequest: ShareMessageRequest, options?: any): AxiosPromise<ShareMessage200Response> {
            return localVarFp.shareMessage(shareMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShareWithSupportRequest} shareWithSupportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareWithSupport(shareWithSupportRequest: ShareWithSupportRequest, options?: any): AxiosPromise<ShareMessage200Response> {
            return localVarFp.shareWithSupport(shareWithSupportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShortNameRequest} shortNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shortName(shortNameRequest: ShortNameRequest, options?: any): AxiosPromise<ShortName200Response> {
            return localVarFp.shortName(shortNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcript(file: File, options?: any): AxiosPromise<Transcript200Response> {
            return localVarFp.transcript(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} initialStart 
         * @param {string} id 
         * @param {File} file 
         * @param {string} input 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transcriptAudio(initialStart: string, id: string, file: File, input: string, options?: any): AxiosPromise<string> {
            return localVarFp.transcriptAudio(initialStart, id, file, input, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWorkflowPublicStatusRequest} updateWorkflowPublicStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowPublicStatus(updateWorkflowPublicStatusRequest: UpdateWorkflowPublicStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkflowPublicStatus(updateWorkflowPublicStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateWorkflowStatusRequest} updateWorkflowStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWorkflowStatus(updateWorkflowStatusRequest: UpdateWorkflowStatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWorkflowStatus(updateWorkflowStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workflowByName(name: string, options?: any): AxiosPromise<WorkflowConfigDto> {
            return localVarFp.workflowByName(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public allWorkflowsConfigs(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).allWorkflowsConfigs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public allWorkflowsWithDescription(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).allWorkflowsWithDescription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} executionId 
     * @param {string} tabName 
     * @param {Array<File>} [attachedFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public attachFileToChat(executionId: string, tabName: string, attachedFiles?: Array<File>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).attachFileToChat(executionId, tabName, attachedFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public callbackApp(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).callbackApp(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContinueAgentsChainRequest} continueAgentsChainRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continueAgentsChain(continueAgentsChainRequest: ContinueAgentsChainRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).continueAgentsChain(continueAgentsChainRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCommentForMessage(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCommentForMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMemory(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteMemory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMessage(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fetchUserInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fetchUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllUsers(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAllWaitingUsers(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAllWaitingUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} executionid 
     * @param {string} tab 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getChatHistory(executionid: string, tab: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getChatHistory(executionid, tab, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocxForMessageId(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDocxForMessageId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMemoryForUser(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMemoryForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMessageById(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMessageById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMessageByIdForUser(messageId: string, userId: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMessageByIdForUser(messageId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMessageHeaders(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMessageHeaders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMessageHeadersForUser(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMessageHeadersForUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMessagesIdsForGroup(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMessagesIdsForGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPdfForMessageId(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getPdfForMessageId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSwaggerConfig(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSwaggerConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUnfinishedRuns(workflow: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getUnfinishedRuns(workflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GiveAccessRoleToUserRequest} giveAccessRoleToUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public giveAccessRoleToUser(giveAccessRoleToUserRequest: GiveAccessRoleToUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).giveAccessRoleToUser(giveAccessRoleToUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [iss] 
     * @param {string} [launch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public launchApp(iss?: string, launch?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).launchApp(iss, launch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [input] 
     * @param {string} [executionId] 
     * @param {string} [workflow] 
     * @param {string} [groupId] 
     * @param {string} [id] 
     * @param {string} [comments] 
     * @param {Array<File>} [attachedFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public processMessage(input?: string, executionId?: string, workflow?: string, groupId?: string, id?: string, comments?: string, attachedFiles?: Array<File>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).processMessage(input, executionId, workflow, groupId, id, comments, attachedFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process input message by After Visit Summary agent and return executionId to check status after
     * @param {RequestMessage} requestMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public processMessageExtWithPrompt(requestMessage: RequestMessage, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).processMessageExtWithPrompt(requestMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} executionId 
     * @param {string} [text] 
     * @param {string} [tabName] 
     * @param {string} [chatMessageId] 
     * @param {Array<File>} [attachedFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public processUserMessageFromChat(executionId: string, text?: string, tabName?: string, chatMessageId?: string, attachedFiles?: Array<File>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).processUserMessageFromChat(executionId, text, tabName, chatMessageId, attachedFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromptToConfigRequest} promptToConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public promptToConfig(promptToConfigRequest: PromptToConfigRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).promptToConfig(promptToConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} executionId 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rawData(executionId: string, fileName: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rawData(executionId, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rawDataForFile(fileName: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rawDataForFile(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefillAccountRequest} refillAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public refillAccount(refillAccountRequest: RefillAccountRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).refillAccount(refillAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Comment} comment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveCommentForMessage(id: string, comment: Comment, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveCommentForMessage(id, comment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} tabName 
     * @param {Array<Question>} [question] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveQuestionsWithAnswersForMessage(id: string, tabName: string, question?: Array<Question>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveQuestionsWithAnswersForMessage(id, tabName, question, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkflowConfigDto} workflowConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveWorkflowConfig(workflowConfigDto: WorkflowConfigDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveWorkflowConfig(workflowConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetMemoryForUserRequest} setMemoryForUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setMemoryForUser(setMemoryForUserRequest: SetMemoryForUserRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setMemoryForUser(setMemoryForUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPermissionToWorkflowRequest} setPermissionToWorkflowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public setPermissionToWorkflow(setPermissionToWorkflowRequest: SetPermissionToWorkflowRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).setPermissionToWorkflow(setPermissionToWorkflowRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShareMessageRequest} shareMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shareMessage(shareMessageRequest: ShareMessageRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).shareMessage(shareMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShareWithSupportRequest} shareWithSupportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shareWithSupport(shareWithSupportRequest: ShareWithSupportRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).shareWithSupport(shareWithSupportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShortNameRequest} shortNameRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public shortName(shortNameRequest: ShortNameRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).shortName(shortNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transcript(file: File, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).transcript(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} initialStart 
     * @param {string} id 
     * @param {File} file 
     * @param {string} input 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public transcriptAudio(initialStart: string, id: string, file: File, input: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).transcriptAudio(initialStart, id, file, input, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWorkflowPublicStatusRequest} updateWorkflowPublicStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWorkflowPublicStatus(updateWorkflowPublicStatusRequest: UpdateWorkflowPublicStatusRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWorkflowPublicStatus(updateWorkflowPublicStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateWorkflowStatusRequest} updateWorkflowStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateWorkflowStatus(updateWorkflowStatusRequest: UpdateWorkflowStatusRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateWorkflowStatus(updateWorkflowStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workflowByName(name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workflowByName(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalAPIApi - axios parameter creator
 * @export
 */
export const ExternalAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the message and its status by executionId. If the message returns with a \'finished\' status set to true, it indicates that all agents have completed their tasks.
         * @param {string} executionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveResultByExecutionId: async (executionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'executionId' is not null or undefined
            assertParamExists('retrieveResultByExecutionId', 'executionId', executionId)
            const localVarPath = `/external/api/v1/message/executionId/{executionId}`
                .replace(`{${"executionId"}}`, encodeURIComponent(String(executionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process input message by Assess workflow. After processing, they return an executionId which can be used to check the status and retrieve the results.
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAssess: async (requestMessage: RequestMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestMessage' is not null or undefined
            assertParamExists('submitAssess', 'requestMessage', requestMessage)
            const localVarPath = `/external/api/v1/processAssess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Process input message by all agents. After processing, they return an executionId which can be used to check the status and retrieve the results.
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProcessFullSummary: async (requestMessage: RequestMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestMessage' is not null or undefined
            assertParamExists('submitProcessFullSummary', 'requestMessage', requestMessage)
            const localVarPath = `/external/api/v1/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalAPIApi - functional programming interface
 * @export
 */
export const ExternalAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the message and its status by executionId. If the message returns with a \'finished\' status set to true, it indicates that all agents have completed their tasks.
         * @param {string} executionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveResultByExecutionId(executionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitProcessFullSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveResultByExecutionId(executionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process input message by Assess workflow. After processing, they return an executionId which can be used to check the status and retrieve the results.
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAssess(requestMessage: RequestMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitProcessFullSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitAssess(requestMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Process input message by all agents. After processing, they return an executionId which can be used to check the status and retrieve the results.
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitProcessFullSummary(requestMessage: RequestMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitProcessFullSummary200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitProcessFullSummary(requestMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalAPIApi - factory interface
 * @export
 */
export const ExternalAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalAPIApiFp(configuration)
    return {
        /**
         * Get the message and its status by executionId. If the message returns with a \'finished\' status set to true, it indicates that all agents have completed their tasks.
         * @param {string} executionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveResultByExecutionId(executionId: string, options?: any): AxiosPromise<SubmitProcessFullSummary200Response> {
            return localVarFp.retrieveResultByExecutionId(executionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Process input message by Assess workflow. After processing, they return an executionId which can be used to check the status and retrieve the results.
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAssess(requestMessage: RequestMessage, options?: any): AxiosPromise<SubmitProcessFullSummary200Response> {
            return localVarFp.submitAssess(requestMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * Process input message by all agents. After processing, they return an executionId which can be used to check the status and retrieve the results.
         * @param {RequestMessage} requestMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitProcessFullSummary(requestMessage: RequestMessage, options?: any): AxiosPromise<SubmitProcessFullSummary200Response> {
            return localVarFp.submitProcessFullSummary(requestMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExternalAPIApi - object-oriented interface
 * @export
 * @class ExternalAPIApi
 * @extends {BaseAPI}
 */
export class ExternalAPIApi extends BaseAPI {
    /**
     * Get the message and its status by executionId. If the message returns with a \'finished\' status set to true, it indicates that all agents have completed their tasks.
     * @param {string} executionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAPIApi
     */
    public retrieveResultByExecutionId(executionId: string, options?: AxiosRequestConfig) {
        return ExternalAPIApiFp(this.configuration).retrieveResultByExecutionId(executionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process input message by Assess workflow. After processing, they return an executionId which can be used to check the status and retrieve the results.
     * @param {RequestMessage} requestMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAPIApi
     */
    public submitAssess(requestMessage: RequestMessage, options?: AxiosRequestConfig) {
        return ExternalAPIApiFp(this.configuration).submitAssess(requestMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Process input message by all agents. After processing, they return an executionId which can be used to check the status and retrieve the results.
     * @param {RequestMessage} requestMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalAPIApi
     */
    public submitProcessFullSummary(requestMessage: RequestMessage, options?: AxiosRequestConfig) {
        return ExternalAPIApiFp(this.configuration).submitProcessFullSummary(requestMessage, options).then((request) => request(this.axios, this.basePath));
    }
}


