import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Card,
    Dropdown,
    Layout,
    Menu,
    message,
    Modal,
    Pagination,
    PaginationProps,
    Progress,
    Tabs,
    theme,
    Tooltip,
    UploadProps
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useApi} from "./ApiProvider";
import {io} from "socket.io-client";
import RecordRTC from 'recordrtc';
import {
    AudioTwoTone,
    CopyOutlined,
    DeleteOutlined,
    DislikeOutlined,
    DownloadOutlined,
    FileDoneOutlined,
    LikeOutlined,
    MenuOutlined,
    PaperClipOutlined,
    SendOutlined
} from "@ant-design/icons"
import {v4 as uuidv4} from 'uuid';
import {Comment, ImportedFileDto, MessageDto, MessageState, QuestionState, WorkflowConfigDto} from "../generated/api";
import {ExampleCase} from "./ExampleCase";
import HighlightableMarkdown from "./chat/HighlightableMarkdown";
import FileSaver from "file-saver";
import Sider from "antd/lib/layout/Sider";
import {Content} from "antd/es/layout/layout";
import Text from "antd/es/typography/Text";
import {useMobileContext} from "./share/MobileProvider";
import {useNavigate} from "react-router-dom";
import Paragraph from "antd/es/typography/Paragraph";
import QuestionTab from "./share/QuestionTab";
import Dragger from "antd/es/upload/Dragger";
import InternalChat from "./chat/InternalChat";


interface ChatInterfaceProps {
    activeChat: string | undefined;
    isNewChat: boolean;
    adminProps?: { disableRun: boolean, disableVoiceInput: boolean, disableAttachFile: boolean };
    exampleCase: boolean;
    saveNewChat: (message: MessageDto) => void
    deleteChat: (executionId: string) => void
    updateHeaders: () => any
    setActiveChat: (id: string) => void
    workflow: string | undefined;
    showMenu: (b: boolean) => void
}

export default function ChatInterface(props: ChatInterfaceProps) {
    const [inputValue, setInputValue] = useState("");
    const [messages, setMessages] = useState<{ [key: string]: string }>({});
    const [currentExecutionId, setCurrentExecutionId] = useState<string>("")
    const [currentGroupId, setCurrentGroupId] = useState<string>("")
    const [currentTypingTab, setCurrentTypingTab] = useState<string | undefined>(undefined)
    const [TABS, setTABS] = useState<string[]>([])
    const [, setTimer] = useState<number | undefined>()
    const [activeTab, setActiveTab] = useState<string>()
    const [tabChangedManually, setTabChangedManually] = useState<boolean>(false)
    const [notProcessedChanges, setNotProcessedChanges] = useState<boolean>(false)
    const [openSummarizationDocModal, setOpenSummarizationDocModal] = useState<boolean>(false)
    const [selectedSummarization, setSelectedSummarization] = useState<string>('')
    const [modalTitle, setModalTitle] = useState<string>('')
    const [workflow, setWorkflow] = useState<WorkflowConfigDto | null>(null)
    const [currentMessage, setCurrentMessage] = useState<any | null>(null)
    const [submitProcessing, setSubmitProcessing] = useState<Map<string, boolean>>(new Map());
    const [executionProgress, setExecutionProgress] = useState<Map<string, number>>(new Map());
    const api = useApi();
    const [isScrolledByUser, setIsScrolledByUser] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]); // Handle an array of files
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [recorder, setRecorder] = useState<RecordRTC | null>(null);
    const [recordingStream, setRecordingStream] = useState<MediaStream | null>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false)
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const currentExecutionIdRef = useRef(currentExecutionId);
    const [userComments, setComments] = useState<Comment[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [groupChatsIds, setGroupChatsIds] = useState<string[]>([])
    const [importedFiles, setImportedFiles] = useState<ImportedFileDto[] | undefined>([])
    const [exportLoading, setExportLoading] = useState(false);
    const [showCommentMenu, setShowCommentMenu] = useState(false);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState<number>();
    const [menuComments, setMenuComments] = useState<any[]>();
    const [buttonText, setButtonText] = useState('Copy');
    const {isMobile} = useMobileContext();
    const [coordinates, setCoordinates] = useState<{ [id: number]: number }>({})
    const navigate = useNavigate();
    const draggeRprops: UploadProps = {
        accept: ".pdf,.mp3,.xml,.json,.m4a,.docx,.txt",
        name: 'file',
        multiple: true,
        beforeUpload: (file: any, FileList: any) => {
        },
        showUploadList: false,
        openFileDialogOnClick: false,
        onDrop(e) {
            handleFileChange(e.dataTransfer.files)
        },
    };

    const onPageChange: PaginationProps['onChange'] = (page) => {
        const index = page - 1
        props.setActiveChat(groupChatsIds[index]);
        setCurrentPage(page);
    };
    useEffect(() => {
        currentExecutionIdRef.current = currentExecutionId;
    }, [currentExecutionId]);

    useEffect(() => {
        refreshCoordinates()
    }, [selectedCommentId]);

    useEffect(() => {
        setCommentsForCommentsMenu(activeTab ?? TABS[0])
    }, [coordinates]);

    const handleFileChange = (files: FileList | null) => {
        const acceptedExtensions = [".pdf", ".mp3", ".xml", ".json", ".m4a", ".docx", ".txt"];
        if (!files) return;
        const maxFileSize = 100 * 1024 * 1024; // 25 MB in bytes
        for (let file of files) {
            if (file.size > maxFileSize) {
                // Inform user
                message.error("File size should be less than 100MB").then()
                return;
            }
            if (!acceptedExtensions.includes(file.name.substring(file.name.lastIndexOf('.')).toLowerCase())) {
                message.error(`Wrong file extension : ${file.name}`).then()
                return;
            }
        }
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
        message.success('Files attached').then(() => {
        });
    };
    const handleFileOpen = (file: File) => {
        if (file.size == 0) console.log('0000')

        if (file.type.includes('plain')) {
            openModal(getSummaryForFile(file), 'FHIR resource content')
        } else if (file.name.includes('mp3') || file.type.includes('m4a')) {
            openModal(getExtractedTextForFile(file), 'FHIR resource content')
        } else {
            api.rawData(props.activeChat!, file.name).then(resp => {
                const byteCharacters = atob(resp.data);
                const byteArray = new Uint8Array([...byteCharacters].map(char => char.charCodeAt(0)));
                const blob = new Blob([byteArray], {type: file.type});
                const url = URL.createObjectURL(blob);
                window.open(url, "_blank");
            }).catch(resp1 => {
                message.error(resp1.response.data).then(r => {
                })
            })
        }
    };
    const startRecording = () => {
        setIsRecording(true)
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
            setRecordingStream(stream)
            let initialStart = true;
            let uuid = currentExecutionId;
            if (!uuid) {
                uuid = uuidv4();
                setCurrentExecutionId(uuid);
            }
            const options = {
                type: 'audio',
                mimeType: 'audio/webm',
                numberOfAudioChannels: 1,
                recorderType: RecordRTC.StereoAudioRecorder,
                checkForInactiveTracks: true,
                timeSlice: 5000,
                ondataavailable: async (blob) => {
                    const initialStartFlag = initialStart ? "true" : "false";
                    if (initialStart) {
                        initialStart = false;
                    }
                    const res = await api.transcriptAudio(initialStartFlag, uuid, new File([blob], "file.webm", {type: "video/webm"}), inputValue);
                    setInputValue(res.data);
                },
            } as RecordRTC.Options;
            const recordRTC = new RecordRTC(stream, options);
            setRecorder(recordRTC);
            recordRTC.startRecording();
            textAreaRef.current?.focus();
        });

    };
    const stopRecording = () => {
        if (recorder) {
            setIsRecording(false);
            recorder.stopRecording(function () {
                recorder.getBlob();
            });
            recordingStream?.getTracks().forEach(function (track) {
                track.stop();
            });
            setRecorder(null);
        }
    };

    function refreshCoordinates() {
        let coordinates: { [id: number]: number } = {};
        let elements = Array.from(document.getElementsByClassName("comment"))
        const tabcomm = userComments.filter((e: Comment) => e.tabName == activeTab);
        elements = elements.filter((el: any) => tabcomm.some((com: Comment) => com.id == Number(el.getAttribute("comment-id"))))
        elements = elements.sort((a: any, b: any) => {
            return Number(a.getAttribute("comment-id")) == selectedCommentId ? -1 : 1;
        })
        const menuCommentsElements = document.getElementsByClassName("commentMenuWrapper");
        let occupiedPositions: any[] = [];
        for (let el of elements) {
            const commentId = Number(el.getAttribute("comment-id"));
            let heightOfComment = 0;
            for (let commentMenuEl of menuCommentsElements) {
                if (commentMenuEl instanceof HTMLElement) {
                    if (Number(commentMenuEl.getAttribute("comment-id")) === commentId) {
                        heightOfComment = commentMenuEl.getBoundingClientRect().height;
                        //need to rework, for some reason height of comments on other tabs is always zero
                        heightOfComment = heightOfComment == 0 ? 50 : heightOfComment;
                        break;
                    }
                }
            }
            let position = el.getBoundingClientRect().top - (document.getElementById("commentsSider" + activeTab)?.getBoundingClientRect().top ?? 350) - heightOfComment / 2 - 15;
            let isOverlapping = true;
            while (isOverlapping) {
                isOverlapping = false; // Assume no overlap initially
                for (let occupiedPosition of occupiedPositions) {
                    if (position < occupiedPosition.bottom && position + heightOfComment > occupiedPosition.top) {
                        position = occupiedPosition.bottom;
                        isOverlapping = true;
                        break;
                    }
                }
            }
            coordinates[commentId] = position;
            occupiedPositions.push({top: position, bottom: position + heightOfComment});
        }
        setCoordinates(coordinates);
    }


    useEffect(() => {
        setCommentsForCommentsMenu(activeTab ?? TABS[0])
    }, [userComments])

    const onTabContentWheel = () => {
        // console.log('onTabContentWjheel')
        const scrollContainer = document.querySelector(".highlightable-markdown-wrapper");
        setIsScrolledByUser(false);
        // Check if the user has scrolled to the bottom of the container
        // console.log('scrollContainer')
        // console.log(scrollContainer)
        // console.log('scrollContainer?.scrollTop + scrollContainer?.clientHeight')
        // console.log(scrollContainer?.scrollTop)
        // console.log( scrollContainer?.clientHeight)
        // console.log('scrollContainer?.scrollHeight')
        // console.log(scrollContainer?.scrollHeight)
        if (scrollContainer
            && (scrollContainer?.scrollTop + scrollContainer?.clientHeight >= scrollContainer?.scrollHeight)) {
            setIsScrolledByUser(false);
            // console.log('setIsScrolledByUser - false')
        } else {
            setIsScrolledByUser(true);
            // console.log('setIsScrolledByUser - true')
        }
    }
    useEffect(() => {
        setCommentsForCommentsMenu(activeTab ?? TABS[0]);
        setTimeout(() => refreshCoordinates(), 150);
    }, [activeTab]);
    useEffect(() => {
        const scrollContainer = document.querySelectorAll(".highlightable-markdown-wrapper");
        if (!isScrolledByUser && scrollContainer && !tabChangedManually && activeTab === currentTypingTab) {
            scrollContainer.forEach(el => {
                el.scrollTop = 10000;
            })
        }
        if (currentTypingTab && activeTab !== currentTypingTab && !tabChangedManually) {
            setActiveTab(currentTypingTab)
        }
    }, [messages, isScrolledByUser]);


    useEffect(() => {
        if (currentTypingTab == "" && props.activeChat) api.getMessageById(props.activeChat).then(msg => {
            if (msg.data.state == MessageState.WaitingForAnswers) {
                const waitingQuestions = msg.data.additionalQuestions?.find(qs => qs.questionState == QuestionState.WaitingForAnswer)
                if (waitingQuestions) {
                    const tab = workflow?.agents.filter((ag) => ag.tabName == waitingQuestions.tabName).map((ag2) => ag2.tabName)?.[0];
                    if (tab) setActiveTab(tab)
                }
            }
        })
    }, [currentTypingTab, props.activeChat])

    useEffect(() => {
        if (currentMessage?.state == MessageState.WaitingForAnswers) {
            if (props.activeChat) api.getMessageById(props.activeChat).then(
                message => {
                    const waitingQuestions = message.data.additionalQuestions?.find(qs => qs.questionState == QuestionState.WaitingForAnswer)
                    if (waitingQuestions) {
                        const tab = workflow?.agents.filter((ag) => ag.tabName == waitingQuestions.tabName).map((ag2) => ag2.tabName)?.[0];
                        if (tab) setActiveTab(tab)
                    }
                })
        } else {
            setActiveTab(TABS[0])
        }
    }, [TABS])

    useEffect(() => {
        if (props.activeChat) {
            setCurrentMessage(null)
            api.getMessagesIdsForGroup(props.activeChat).then(r => {
                setGroupChatsIds(r.data)
            })
        }
        setTabChangedManually(false);
        if (!props.isNewChat) { //TODO: figure out
            setCurrentExecutionId("")
            setCurrentTypingTab(undefined)
        }
        if (props.activeChat) {
            api.getMessageById(props.activeChat).then(r => {
                setMessages(Object.fromEntries((r.data.content ?? []).map(x => {
                    return [x.tabName, x.content]
                })))
                if (r.data.workflow) {
                    api.workflowByName(r.data.workflow).then(f => {
                        setWorkflow(f.data)
                        const tabsForFlow: string[] = f.data.agents.map(d => d.tabName ?? "")
                        setTABS(tabsForFlow)
                        setComments(r.data.comments ?? [])
                    })
                } else {
                    let tabs: string[] | undefined = r.data.content?.map(content => content.tabName);
                    if (tabs && tabs.length !== 0) setTABS(tabs)
                }
                setCurrentMessage(r.data)
                setInputValue(r.data.input)
                setFiles(r.data.importedFile);
                props.showMenu(!r.data.externalCall)
                setCurrentExecutionId(r.data.executionId);
                if (r.data.groupId !== '') {
                    setCurrentGroupId(r.data.groupId);
                }
                setTimeout((refreshCoordinates), 100)
            })
        } else {
            setInputValue('')

            if (props.workflow) {
                api.workflowByName(props.workflow).then(f => {
                    setWorkflow(f.data)
                    if (props.exampleCase) setInputValue(f.data.exampleCase ?? '')
                    const tabsForFlow: string[] = f.data.agents.map(d => d.tabName ?? "")
                    setTABS(tabsForFlow)
                })
            }
            setComments([])
            setNotProcessedChanges(false);
            setSelectedSummarization('')
            setOpenSummarizationDocModal(false)
            setCurrentGroupId('')
            setGroupChatsIds([])
            setMessages({})
            setImportedFiles([])
        }
        setSelectedFiles([]);
        setNotProcessedChanges(false);
    }, [props.activeChat, props.workflow])

    useEffect(() => {
        if (workflow && !props.activeChat && workflow.autoRun) handleSubmit()
    }, [workflow])

// Defining a method to artificially increase the progress
    const incrementProgress = (executionId: string, incrementValue: number) => {
        setExecutionProgress(prevExecProgress => {
            const currentProgress = prevExecProgress.get(executionId) ?? 0;
            const newProgress = currentProgress + incrementValue;
            if (newProgress < 100) {
                return new Map(prevExecProgress.set(executionId, newProgress));
            }
            return prevExecProgress; // If progress is already 100 or more, no need to increase it
        });
    };

    const periodicallyIncreaseProgress = (executionId: string) => {
        const incrementInterval = setInterval(() => {
            const randomIncrement = 2
            incrementProgress(executionId, randomIncrement);
        }, 1000 * (1 + Math.floor(Math.random() * 12)));

        return () => clearInterval(incrementInterval); // Clear interval on component unmount
    };
    useEffect(() => {
        const newSocket = io({transports: ['websocket', 'polling']})
        newSocket.on("newToken", (data: string) => {
            const llmToken: {
                token: string;
                executionId: string;
                tabName: string;
            } = JSON.parse(data);
            setCurrentExecutionId(currentExecutionId => {
                if (currentExecutionId === llmToken.executionId) {
                    setCurrentTypingTab(llmToken.tabName)
                    setTimer(old => {
                        if (old) window.clearTimeout(old)
                        return window.setTimeout(() => {
                            setCurrentTypingTab("")
                        }, 4000)
                    });
                    setMessages(prevMessages => {
                        let newMessages = {...prevMessages}
                        newMessages[llmToken.tabName] = llmToken.token
                        return newMessages
                    });
                }
                return currentExecutionId
            })
        });
        newSocket.on("openAiError", (data: string) => {
            const state: {
                executionId: string;
                valid: boolean;
                message?: string;
            } = JSON.parse(data);
            if (state.message) message.error("LLM error: " + state.message).then(r => {
            });
            setSubmitProcessing(prevSubmitProcessing => new Map(prevSubmitProcessing.set(state.executionId, false)));
        });
        newSocket.on("extractionProgress", (data: string) => {
            const processingState: {
                executionId: string;
                docsTotal: number;
                docsProcessed: number;
            } = JSON.parse(data);
            let progress = (processingState.docsProcessed / processingState.docsTotal) / 2 * 100;
            setExecutionProgress(prevExecProgress =>
                new Map(prevExecProgress.set(
                    processingState.executionId,
                    Math.round(progress)
                ))
            );

        });

        newSocket.on("validationIncomplete", (data: string) => {
            const state: {
                executionId: string;
                valid: boolean;
                message?: string;
            } = JSON.parse(data);
            if (state.valid) {
                api.shortName({
                    executionId: state.executionId
                }).then(response => {
                    if (response.data.input) setInputValue(response.data.input)
                    props.updateHeaders()
                }).catch(e => {
                    message.error('Error generating short name');
                });
                if (state.executionId && (!currentExecutionId || currentExecutionId == state.executionId)) {
                    api.getMessageById(state.executionId).then(r => setFiles(r.data.importedFile))
                }
            }
            if (!state.valid) {
                props.deleteChat(state.executionId)
                setSelectedFiles([])
                setFiles([])
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                navigate("/")
            }
            if (state.message) message.error(state.message).then(r => {
            });
            setSubmitProcessing(prevSubmitProcessing => new Map(prevSubmitProcessing.set(state.executionId, false)));
        });
        newSocket.on("summarizationProgress", (data: string) => {
            const processingState: {
                executionId: string;
                docsTotal: number;
                docsProcessed: number;
            } = JSON.parse(data);
            if (processingState.docsProcessed === processingState.docsTotal) {
                setExecutionProgress(prevExecProgress => new Map(prevExecProgress.set(processingState.executionId, 100)));
            } else {
                const currentProgress = 50 + Math.round(((processingState.docsProcessed / processingState.docsTotal) / 2 * 100))
                setExecutionProgress(prevExecProgress => new Map(prevExecProgress.set(processingState.executionId, currentProgress)));

            }
        });
        return () => {
            newSocket.disconnect();
        };
    }, []);


    const exportToDoc = () => {
        if (props.activeChat != null) {
            api.getDocxForMessageId(props.activeChat, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
            }).then(response => {
                const blob = new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });
                FileSaver.saveAs(blob, 'message.docx');
            }).catch(e => {
                message.error(e.data.message).then(() => {
                })
            });
        }
    };

    const exportToPdf = () => {
        if (props.activeChat != null) {
            setExportLoading(true);
            const caseName = currentMessage?.shortName ?? 'message';
            api.getPdfForMessageId(props.activeChat, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf'
                }
            }).then(response => {
                const blob = new Blob([response.data], {type: 'application/pdf'});
                FileSaver.saveAs(blob, `${caseName}.pdf`);
            }).catch(e => {
                message.error(e?.data?.message).then(() => {
                })
            }).finally(() => {
                // Stop loading
                setExportLoading(false);
            });
        }
    };

    const handleFileDelete = (index: number) => {
        setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!isRecording) {
            setInputValue(e.target.value);
        }
    };

    function setFiles(files: ImportedFileDto[] | undefined) {
        setImportedFiles(files)
        if (files && files.length > 0) {
            const nativeFiles = files.map(file => {
                const blob = new Blob([file.rawData!], {type: file.type})
                return new File([blob], file.fileName, {type: file.type})
            })
            setSelectedFiles(nativeFiles);

        }
    }

    const handleSubmit = () => {
        console.log('handleSubmit()')
        // if (!inputValue.trim() && selectedFiles.length === 0) return;
        {
            if (inputValue.length > 30000) {
                message.error("The input exceeds the allowable limit. Please, reduce its length and try again.").then(r => {
                });
                return;
            }
            let groupId = currentGroupId
            if (groupId === "") {
                groupId = uuidv4();
                setCurrentGroupId(groupId);
            }
            let uuid = uuidv4();
            setCurrentExecutionId(uuid);
            setSubmitProcessing(prevSubmitProcessing => new Map(prevSubmitProcessing.set(uuid, true)));
            let processMessage = (fileData: Awaited<{ data: string; file: File }>[] | undefined) => {
                let attachedFiles
                if (fileData) {
                    attachedFiles = fileData.map(({data, file}) => file);
                    periodicallyIncreaseProgress(uuid);
                }
                api.processMessage(inputValue, uuid, props.workflow ?? 'Consult', groupId, undefined, JSON.stringify(userComments), attachedFiles ? attachedFiles : undefined).then(response => {
                    setComments(response.data.message.comments ?? []);
                    setMessages({})
                    setCurrentPage(groupChatsIds.length + 1)
                    setGroupChatsIds(groupChatsIds => [...groupChatsIds, response.data.message.executionId])
                    props.saveNewChat(response.data.message)
                    setCurrentExecutionId(response.data.executionId)
                    setActiveTab(TABS[0]);
                    navigate('/' + response.data.executionId)
                }).catch(e => {
                    console.log(e)
                    message.error(e?.response?.data).then(r => {
                    })
                    setSubmitProcessing(prevSubmitProcessing => new Map(prevSubmitProcessing.set(currentExecutionIdRef.current, false)));
                });
            }
            if (selectedFiles && selectedFiles.length > 0) {
                const promises = selectedFiles.map((file) => {
                    return new Promise<{ data: string, file: File }>((resolve, reject) => {
                        let fileReader = new FileReader();
                        fileReader.readAsDataURL(file);
                        fileReader.onloadend = () => resolve({data: fileReader.result as string, file});
                        fileReader.onerror = () => reject(new Error("File reading failed"));
                    });
                });
                Promise.all(promises)
                    .then((filesAndData) => {
                        // Now you have an array of objects, each containing the base64 encoded data and the File object
                        processMessage(filesAndData);
                    })
                    .catch((error) => {
                        console.error("An error occurred while reading the files:", error);
                    });

            } else {
                processMessage(undefined);
            }
        }
    }
    const addCommentToTab = async (comment: any) => {
        if (props.activeChat) {
            await api.saveCommentForMessage(props.activeChat.toString(), comment).then(comm => {
                setComments(prevState => [...prevState ?? [], comm.data])
                setShowCommentMenu(true)
                setNotProcessedChanges(true);
                setTimeout(() => handleCommentClick(comm.data.id), 100)
            });
        }
    }

    async function deleteComment(id?: number) {
        await api.deleteCommentForMessage(id ?? 0).then(() => {
            const updatedComments = userComments?.filter(comment => comment?.id !== id);
            setComments(updatedComments);
            if (userComments == undefined || userComments.length == 0) {
                setShowCommentMenu(false)
            }
        })
        return undefined;
    }

    function setCommentsForCommentsMenu(activeTab: string | undefined) {
        const comments = userComments?.filter(comm => comm.tabName === activeTab).map((comment) => (
            <div className="commentMenuWrapper" active-tab={comment.tabName} comment-id={comment.id?.toString()}
                 key={comment.id}
                 style={{
                     position: "absolute",
                     left: 0,
                     right: 0,
                     padding: 5,
                     marginTop: 5,
                     top: coordinates[comment.id!]
                 }}>
                <Card size="small" className="commentMenuCard" comment-id={comment.id?.toString()} key={comment.id}
                      bodyStyle={{
                          paddingTop: '6px',
                          paddingBottom: '6px',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                      }}
                      style={{
                          visibility: coordinates[comment.id!] ? 'visible' : 'hidden',
                          // backgroundColor: '#f5f5f5',
                          backgroundColor: '#ffffff',
                          // position: "absolute",
                      }}>
                    {comment.type === 'COMMENT' ? (
                        <>
                            <div style={{gap: 5, display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                <Text style={{width: '100%'}}>{comment.userComment}</Text>
                                <Tooltip style={{padding: '5px'}} title="Delete comment"
                                         className="deleteTooltipCommentMenu">
                                    <DeleteOutlined className={'deleteIconCommentMenu'}
                                                    onClick={() => deleteComment(comment.id)}/>
                                </Tooltip>
                            </div>
                        </>
                    ) : comment.type === 'THUMBS_UP' ? (
                        <LikeOutlined/>
                    ) : comment.type === 'THUMBS_DOWN' ? (
                        <DislikeOutlined/>
                    ) : null}
                </Card>
            </div>
        ));


        if (comments == undefined || comments?.length == 0) {
            setShowCommentMenu(false)
        } else {
            setShowCommentMenu(true)
        }
        setMenuComments(comments)
    }

    const handleCopyToClipboard = (tab: string) => {
        setButtonText('Copied!');
        setTimeout(() => {
            setButtonText('Copy');
        }, 5000);
        if (navigator?.clipboard) {
            if (activeTab) {
                navigator.clipboard.writeText(messages[tab])
                    .then(() => {
                    })
                    .catch(err => console.error('Failed to copy', err));
            }
        }
    };

    function openModal(summary: string, modalTitle: string) {
        if (summary === '') return;
        setSelectedSummarization(summary)
        setModalTitle(modalTitle)
        setOpenSummarizationDocModal(true);
    }

    function getSummaryForFile(file: File): string {
        if (!importedFiles) return '';
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name
        })
        return arr?.[0].summarizationText ?? ''
    }

    function getExtractedTextForFile(file: File): string {
        if (!importedFiles) return '';
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name
        })
        return arr?.[0].extractedText ?? ''
    }

    function getFileNameForFile(fileName: string) {
        return fileName.includes(".") ? fileName.split('.')[0] : fileName;
    }

    function isSummarizationForFile(file: File) {
        if (!importedFiles) return;
        if (!file) return;
        const arr = importedFiles?.filter((im) => {
            return im.fileName === file.name
        })
        return arr && arr.length !== 0 && arr[0].summarizationText !== '';
    }

    const renderDropdownMenu = () => (
        <Menu>
            <Menu.Item> <Button
                onClick={() => fileInputRef.current?.click()}
                style={{marginRight: 5}}
                icon={<PaperClipOutlined/>}>
                Attach File
            </Button>
            </Menu.Item>
            <Menu.Item>
                <Button
                    onClick={exportToPdf}
                    // onClick={exportToDoc}
                    style={{marginRight: 0}}
                    disabled={messages[TABS[0]] === undefined || (TABS[0] === currentTypingTab)}
                    type="default" icon={<DownloadOutlined/>}>
                    Export
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button style={{marginRight: '10px'}}
                        type="link" onClick={() => setInputValue(ExampleCase.text)}>
                    Example Case
                </Button>
            </Menu.Item>
        </Menu>
    );
    useEffect(() => {
        if (groupChatsIds.length === 0) {
            setNotProcessedChanges(false);
            return;
        }
        if (groupChatsIds.length > 1 && currentPage <= groupChatsIds.length) {
            setNotProcessedChanges(false);
            return;
        }
        let haveOlderComments = false;
        if (currentMessage?.comments) {
            haveOlderComments = currentMessage?.comments?.some((comm: Comment) => {
                if (!comm.addedDate) return false;
                return new Date(comm.addedDate) > new Date(currentMessage.createdDate)
            })
            if (!haveOlderComments) {
                haveOlderComments = userComments?.some((comm: Comment) => {
                    if (!comm.addedDate) return false;
                    return new Date(comm.addedDate) > new Date(currentMessage.createdDate)
                })
            }
        }
        if (currentMessage?.input == null || currentMessage?.input === '' || inputValue === '') {
            setNotProcessedChanges(false)
            return;
        }
        setNotProcessedChanges(currentMessage?.input !== inputValue || haveOlderComments);
    }, [userComments, inputValue, currentExecutionId])
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();


    const handleCommentClick = async (id: any) => {
        setShowCommentMenu(true)
        const menuCommentsElements = document.getElementsByClassName("commentMenuCard");
        for (let el of menuCommentsElements) {
            if (el instanceof HTMLElement) {
                const commentId = Number(el.getAttribute("comment-id"));
                if (commentId == id) {
                    el.style.paddingTop = '20px'
                    el.style.paddingBottom = '20px'
                    el.style.boxShadow = '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                } else {
                    el.style.paddingTop = '5px'
                    el.style.paddingBottom = '5px'
                    el.style.boxShadow = ''
                }
            }
        }
        setSelectedCommentId(id);
    }

    return <>
        <div style={{
            position: 'absolute',
            top: '5px',
            left: '12px',
            opacity: 0.45,
            zIndex: 10 // Ensures the text appears above the TextArea
        }}>
            {workflow?.description}
        </div>
        {workflow?.autoRun ? <></> : <div style={{paddingTop: 15}}>
            <div style={{position: 'relative'}}>
                <Dragger {...draggeRprops}>
                    <TextArea
                        ref={textAreaRef}
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={workflow?.ui?.inputDescription ? workflow?.ui?.inputDescription : "Please provide case details by typing, speaking, or uploading documentation."}
                        autoSize={{
                            minRows: (new URLSearchParams(window.location.search).get('external')) == null ? 6 : 3,
                            maxRows: 10
                        }}
                    />
                </Dragger>
                {currentMessage?.summarizationText && currentMessage?.summarizationText !== '' ? <FileDoneOutlined
                    style={{
                        backgroundColor: 'yellow',
                        border: 'none',
                        position: 'absolute',
                        top: '6px',
                        right: '25px'
                    }}
                    title={"Input summarization"}
                    onClick={() => {
                        openModal(currentMessage?.summarizationText ?? '', 'Input summarization')
                    }}/> : <></>}
                {props.adminProps?.disableVoiceInput ? <></> : <Button
                    className={isRecording ? 'recording' : ''}
                    shape='circle'
                    onClick={isRecording ? stopRecording : startRecording}
                    icon={<AudioTwoTone/>}
                    size='large'
                    style={{
                        border: 'none',
                        position: 'absolute',
                        bottom: '10px',
                        right: '17px'
                    }}
                />}
            </div>
        </div>}
        {workflow?.autoRun ? <></> : <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', alignItems: 'start'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '10px'}}>
                    {props.adminProps?.disableRun ? <></> :
                        (<div>
                            <Tooltip open={notProcessedChanges} showArrow={true} color={'gold'} placement="top"
                                     title={'Apply Changes'}>
                                {props.adminProps?.disableRun ? <></> : <Button
                                    loading={submitProcessing.get(currentExecutionId) ?? false}
                                    disabled={!inputValue && (selectedFiles.length === 0)}
                                    type="primary" onClick={handleSubmit} icon={<SendOutlined/>}>
                                    Run
                                </Button>}
                            </Tooltip>
                            {props.adminProps?.disableAttachFile ? <></> : <Button
                                onClick={() => fileInputRef.current?.click()}
                                style={{marginRight: 5, marginLeft: 5}}
                                icon={<PaperClipOutlined/>}
                            >
                                Attach File
                            </Button>}
                        </div>)}
                    {(submitProcessing.get(currentExecutionId) && selectedFiles.length > 0) &&
                        <Progress percent={executionProgress.get(currentExecutionId) ?? 0} size="small"
                                  status="active"/>
                    }
                </div>
                {groupChatsIds.length !== 0 && groupChatsIds.length > 1 ?
                    <Pagination size={"small"} simple current={currentPage} onChange={onPageChange}
                                total={groupChatsIds.length * 10}/> : <></>}
            </div>

            <div>
                <input
                    type="file"
                    onChange={(e) => {
                        handleFileChange(e.target.files)
                    }}
                    style={{display: 'none'}}
                    ref={fileInputRef}
                    accept=".pdf,.mp3,.xml,.json,.m4a,.docx,.txt"
                    multiple // Allow selecting multiple files
                />{isMobile ? (<div style={{marginRight: "5px"}}><Dropdown overlay={renderDropdownMenu()}>
                    <Button type={"primary"} shape={"circle"}><MenuOutlined/></Button>
                </Dropdown></div>) :
                (<>

                    {props.adminProps?.disableAttachFile ? <></> : <Button
                        onClick={exportToPdf}
                        // onClick={exportToDoc}
                        style={{marginRight: 0}}
                        loading={exportLoading}
                        disabled={messages[TABS[0]] === undefined || (TABS[0] === currentTypingTab)}
                        type="default" icon={<DownloadOutlined/>}
                    >
                        Export
                    </Button>}
                </>)}
            </div>
        </div>}
        <Paragraph ellipsis={{rows: 6, expandable: true, symbol: 'more'}}>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
            }}>
                {workflow?.autoRun ? <></> : selectedFiles.map((file, index) => (
                    <div key={file.name} style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '5px'
                    }}>
                        <Button
                            onClick={() => handleFileOpen(file)}
                            style={{maxWidth: 'max-content', padding: '0 1px'}}
                            type="link"
                        >
                            <PaperClipOutlined style={{marginRight: '-5px'}}/>
                            {getFileNameForFile(file.name)}
                        </Button>
                        <DeleteOutlined className="delete-file-icon" size={1} onClick={() => handleFileDelete(index)}
                                        style={{color: '#fd4343', marginLeft: "1px"}}/>
                        {isSummarizationForFile(file)
                            // && !file.type.includes('plain')
                            ?
                            <FileDoneOutlined title={"Document summarization"}
                                              style={{backgroundColor: 'yellow', marginLeft: "6px"}}
                                              onClick={() => {
                                                  openModal(getSummaryForFile(file), 'File information')
                                              }}/> : <></>}
                    </div>
                ))}</div>
        </Paragraph>
        <Layout
            style={{
                padding: '0',
                display: 'flex',
                flexDirection: 'row',
                background: colorBgContainer,
                borderRadius: borderRadiusLG
            }}>

            <Content style={{padding: '0', width: '100px', minHeight: 280}}>
                <div style={{position: 'relative', height: '100%', backgroundColor: '#f5f5f5'}}>
                    <div style={{display: 'flex', height: '100%'}}>
                        <Tabs type="card"
                              style={{width: '100%'}}
                              destroyInactiveTabPane={true}
                              activeKey={activeTab}
                              onChange={key => {
                                  setActiveTab(key)
                                  key === currentTypingTab ? setTabChangedManually(false) : setTabChangedManually(true);
                              }}
                              items={
                                  TABS.filter(tab => tab !== "").map((tab) => ({
                                          label: <span
                                              style={{fontSize: 'medium'}}>{tab} {tab === currentTypingTab && workflow?.agents.filter((ag) => ag.tabName === tab).pop()?.type !== 'chat' ?
                                              <span style={{marginLeft: 10}}>
                                          <div className="dot-flashing"/>
                    </span> : <></>
                                          }
                </span>,
                                          disabled: !messages[tab],
                                          key: tab,
                                          children: <>
                                              <div style={{display: 'flex', flex: 1}}>
                                                  <div className={'tab-content'} style={{
                                                      width: '100%',
                                                      position: 'relative',
                                                      display: 'flex',
                                                      flexDirection: "column",
                                                      flex: 1
                                                  }}>
                                                      {(() => {
                                                          const agentType = workflow?.agents.filter((ag) => ag.tabName === tab).pop()?.type;
                                                          switch (agentType) {
                                                              case 'parallel':
                                                                  return (
                                                                      <QuestionTab
                                                                          messageId={props.activeChat}
                                                                          tabName={activeTab ?? ''}
                                                                          setTabActive={(value: string) => {
                                                                              if (!messages[tab]) {
                                                                                  messages[tab] = value;
                                                                              }
                                                                          }}
                                                                      />
                                                                  );
                                                              case 'chat':
                                                                  return (
                                                                      <InternalChat executionId={currentExecutionId}
                                                                                    chatName={"AI assistant"}
                                                                                    tabName={tab}
                                                                      />
                                                                  );
                                                              default:
                                                                  return (
                                                                      <HighlightableMarkdown
                                                                          tabContentScroll={() => refreshCoordinates()}
                                                                          tabContentWheel={onTabContentWheel}
                                                                          commentClick={handleCommentClick}
                                                                          addComment={addCommentToTab}
                                                                          content={messages[tab]}
                                                                          userComments={userComments}
                                                                          executionId={currentExecutionId}
                                                                          tabName={tab}
                                                                          activeTab={activeTab}
                                                                      />
                                                                  );
                                                          }
                                                      })()}
                                                      {workflow?.agents.filter((ag) => ag.tabName === tab).pop()?.type != 'chat' ?
                                                          <Button
                                                              style={{
                                                                  position: 'absolute',
                                                                  top: 20,
                                                                  right: 25,
                                                                  zIndex: 1000
                                                              }}
                                                              onClick={() => handleCopyToClipboard(tab)}
                                                          >
                                                              <CopyOutlined style={{fontSize: 15}}/>{buttonText}
                                                          </Button> : <></>}
                                                  </div>
                                                  {showCommentMenu ? <Sider
                                                      id={'commentsSider' + tab}
                                                      style={{
                                                          background: '#ffffff',
                                                          height: '100%',
                                                          display: 'flex',
                                                          flexDirection: 'column',
                                                          overflow: 'hidden'
                                                      }}
                                                      width={200}>
                                                      <div
                                                          style={{
                                                              position: "absolute",
                                                              width: '100%',
                                                          }}>
                                                          <p>{menuComments}</p>
                                                      </div>
                                                  </Sider> : <></>}
                                              </div>
                                          </>
                                      })
                                  )
                              }/>
                    </div>
                </div>
            </Content>

        </Layout>
        <Modal
            title={modalTitle}
            open={openSummarizationDocModal}
            onCancel={() => setOpenSummarizationDocModal(false)}
            footer={[]}
            width={800}
        >
            <Text>
                <pre>{selectedSummarization} </pre>
            </Text>
        </Modal>
        <div style={{marginTop: 1, marginBottom: 1, textAlign: 'center'}}>
            <div style={{
                color: 'grey',
                opacity: 0.7,
                fontStyle: 'italic',
                borderRadius: '1px'
            }}>
                Information is provided for informational purposes only and should not replace professional judgment.
            </div>
        </div>
    </>

}
